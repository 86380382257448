<template>
  <div class="auth-providers">
    <ZButton
      outline-thin
      block
      @click="
        triggerExternalAuth({
          provider: EXTERNAL_AUTHENTICATION_PROVIDERS.Facebook,
          isSignupEvent: isImplicitSignup,
          intent,
        })
      "
    >
      <IconFacebook />
      <span>{{ t('continueWith', { provider: 'Facebook' }) }}</span>
    </ZButton>
    <ZButton
      outline-thin
      block
      @click="
        triggerExternalAuth({
          provider: EXTERNAL_AUTHENTICATION_PROVIDERS.Google,
          isSignupEvent: isImplicitSignup,
          intent,
        })
      "
    >
      <IconGoogle />
      <span>{{ t('continueWith', { provider: 'Google' }) }}</span>
    </ZButton>
    <ZButton
      outline-thin
      block
      @click="
        triggerExternalAuth({
          provider: EXTERNAL_AUTHENTICATION_PROVIDERS.Apple,
          isSignupEvent: isImplicitSignup,
          intent,
        })
      "
    >
      <IconApple />
      <span>{{ t('continueWith', { provider: 'Apple' }) }}</span>
    </ZButton>

    <ZButton
      v-if="authType !== AUTHENTICATION_TYPE.Login"
      outline-thin
      block
     
      @click="emit('show-signup-form')"
    >
      <IconEnvelope />
      <span>{{ t('signUpWithEmail') }}</span>
    </ZButton>

    <ZButton
      v-if="authType !== AUTHENTICATION_TYPE.Signup"
      outline-thin
      block
     
      @click="emit('show-login-form')"
    >
      <IconEnvelope />
      <span>{{ t('continueWithEmail') }}</span>
    </ZButton>
  </div>
</template>

<script setup lang="ts">
import type { AUTHENTICATION_INTENT } from '~/constants/authentication'
import {
  AUTHENTICATION_TYPE,
  EXTERNAL_AUTHENTICATION_PROVIDERS,
} from '~/constants/authentication'
import type { Nullable } from '~/types'

const props = withDefaults(defineProps<{
  authType?: Nullable<AUTHENTICATION_TYPE>
  intent?: AUTHENTICATION_INTENT
}>(), {
  authType: null,
})

const emit = defineEmits<{
  'show-signup-form': []
  'show-login-form': []
}>()

const { t } = useI18n({
  useScope: 'local',
})

const isImplicitSignup = computed(() => !props.authType || props.authType === AUTHENTICATION_TYPE.Signup)

const { triggerExternalAuth } = useExternalAuthentication()
</script>

<style lang="scss" scoped>
.auth-providers {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  white-space: nowrap;

  .zbtn {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 1.25rem;
      width: 1.25rem;
      margin-right: 0.5rem;
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "continueWithEmail": "Continue with email",
    "continueWith": "Continue with {provider}",
    "signUpWithEmail": "Sign up with email"
  },
  "fr": {
    "continueWithEmail": "Continuez par courriel",
    "continueWith": "Continuez avec {provider}",
    "signUpWithEmail": "Inscrivez-vous par courriel"
  }
}
</i18n>
