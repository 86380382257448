<template>
  <ZDropdown
    v-if="user"
    nav
   
  >
    <template #button-content>
      <ZAvatar
        :text="initials"
        size="sm"
        :src="user.Avatar"
      />
    </template>
    <ZDropdownItem
      :to="localePath({ name: 'profile-id', params: { id: user.Id }, query: { cta: 'header-nav-avatar' } })"
     
    >
      {{ t('pageLink.profile') }}
    </ZDropdownItem>
    <ZDropdownItem
      :to="localePath('dashboard-profile')"
     
    >
      {{ t('pageLink.account') }}
    </ZDropdownItem>
    <ZDropdownItem
      class="logout"
     
      @click.prevent="logout()"
    >
      {{ t('actions.logOut') }}
    </ZDropdownItem>
  </ZDropdown>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const { user, logOut } = useAuthentication()
const { initials } = useUser()
const { t } = useI18n()

async function logout() {
  await logOut()
}
</script>

<style scoped lang="scss">
:deep(.logout) {
  cursor: pointer;
}
</style>
