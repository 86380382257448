<template>
  <button
   
    type="button"
    class="close"
    aria-label="Clear"
    @mousedown.prevent="handleClick"
    @touchstart.prevent="handleClick"
  >
    <IconClearLight v-if="variant === 'light'" />
    <IconClearDark v-else />
  </button>
</template>

<script setup lang="ts">
import IconClearLight from '~/assets/images/clear-light.svg'
import IconClearDark from '~/assets/images/clear-dark.svg'
import type { ClearButtonVariant } from '~/types/style-guide'

withDefaults(defineProps<{
  variant?: ClearButtonVariant
}>(), {
  variant: 'light',
})

const emit = defineEmits<{
  click: []
}>()

function handleClick() {
  emit('click')
}
</script>

<style scoped lang="scss">
.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}

button.close {
  background-color: transparent;
  border: 0;
  padding: 0;
}
</style>
