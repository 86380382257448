<template>
  <div class="app-download-buttons">
    <template v-if="isMobileOrTablet">
      <a
       
        href="#"
        @click.prevent="downloadApp()"
      >
        <img
         
          :alt="downloadButtonImage.alt"
          :class="btnSize"
          class="store-button"
          :src="downloadButtonImage.src"
          loading="lazy"
          :width="downloadButtonImage.width"
          :height="downloadButtonImage.height"
        >
      </a>
    </template>
    <template v-else>
      <a
        class="app-link"
       
        @click.prevent="downloadApp({ platform: 'ios' })"
      >
        <img
          :alt="iTunesButtonImage.alt"
          :class="btnSize"
          class="store-button"
          :src="iTunesButtonImage.src"
          loading="lazy"
          :width="iTunesButtonImage.width"
          :height="iTunesButtonImage.height"
        >
      </a>
      <a
        class="app-link"
       
        @click.prevent="downloadApp({ platform: 'android' })"
      >
        <img
          :alt="playStoreButtonImage.alt"
          class="store-button"
          :class="btnSize"
          :src="playStoreButtonImage.src"
          loading="lazy"
          :width="playStoreButtonImage.width"
          :height="playStoreButtonImage.height"
        >
      </a>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ButtonSize } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  btnSize?: ButtonSize
}>(), {
  btnSize: 'sm',
})

const { t } = useI18n()

const { isAndroid, isMobileOrTablet } = useDevice()
const runtimeConfig = useRuntimeConfig()
const { locale } = useI18n()
const { downloadApp } = useDownloadApp()

const imgSize = computed(() => {
  switch (props.btnSize) {
    case 'lg':
      return {
        width: '189',
        height: '56',
      }
    case 'md':
      return {
        width: '162',
        height: '48',
      }
    default:
      return {
        width: '108',
        height: '32',
      }
  }
})

const downloadButtonImage = computed(() => {
  return isAndroid ? playStoreButtonImage.value : iTunesButtonImage.value
})

const playStoreButtonImage = computed(() => {
  return {
    src: `${runtimeConfig.public.staticAssetsURL}/app/download/badge-play-store_${locale.value}.svg`,
    alt: t('appDownloadBanner.googlePlayAction'),
    ...imgSize.value,
  }
})

const iTunesButtonImage = computed(() => {
  return {
    src: `${runtimeConfig.public.staticAssetsURL}/app/download/badge-app-store_${locale.value}.svg`,
    alt: t('appDownloadBanner.iTunesAction'),
    ...imgSize.value,
  }
})
</script>

<style lang="scss" scoped>
.app-download-buttons {
  display: flex;
  gap: 0.5rem;
}

.app-link {
  cursor: pointer;
  display: block;
}

.store-button {
  width: auto;
  display: block;

  &.md {
    height: 3rem;
  }

  &.lg {
    height: 3.5rem;
  }
}
</style>

<i18n src="~/locales/common/app-download-banner.json" lang="json" />
