<template>
  <ClientOnly>
    <div
      v-if="isVisible"
      v-bind="$attrs"
      ref="popoverEl"
      class="zpopover"
      :style="floatingStyles"
     
    >
      <slot
        :is-visible="isVisible"
        :close="hide"
      />
      <div
        v-if="!noArrow"
        ref="arrowEl"
        class="arrow"
        :style="arrowStyles"
      />
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { type Placement, arrow, autoUpdate, offset, shift, useFloating } from '@floating-ui/vue'
import { ARROW_BORDERS, ARROW_PLACEMENTS } from './constants'
import type { ArrowPlacement, PopoverPlacement } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  placement?: Placement
  noArrow?: boolean
}>(), {
  placement: 'bottom',
})

defineOptions({
  inheritAttrs: false,
})

const isVisible = ref(false)
const popoverEl = ref<HTMLElement>()
const arrowEl = ref<HTMLElement>()
const triggerRef = ref<HTMLElement>()

onClickOutside(
  popoverEl,
  () => hide(),
  { ignore: [triggerRef] },
)

const { floatingStyles, middlewareData } = useFloating(triggerRef, popoverEl, {
  placement: props.placement,
  whileElementsMounted: autoUpdate,
  middleware: [
    offset(props.noArrow ? 0 : 16),
    shift(),
    arrow({ element: arrowEl, padding: 8 }),
  ],
})

const popoverPlacement = props.placement.split('-')[0] as PopoverPlacement
const arrowPlacement = ARROW_PLACEMENTS[popoverPlacement] as ArrowPlacement
const borderWidth = ARROW_BORDERS[arrowPlacement]

const arrowStyles = computed(() => {
  return {
    'left':
          middlewareData.value.arrow?.x != null
            ? `${middlewareData.value.arrow.x}px`
            : '',
    'top':
      middlewareData.value.arrow?.y != null
        ? `${middlewareData.value.arrow.y}px`
        : '',
    [arrowPlacement]: arrowEl.value?.offsetWidth ? `${-arrowEl.value?.offsetWidth / 2}px` : '',
    'border-width': borderWidth,
  }
})

defineExpose({
  toggleShow,
  isVisible: computed(() => isVisible.value),
})

function toggleShow(target: HTMLElement) {
  triggerRef.value = target

  isVisible.value = !isVisible.value
}

function hide() {
  isVisible.value = false
}
</script>

<style lang="scss" scoped>
.zpopover {
  background-color: #fff;
  padding: 1rem;
  z-index: 15;
  word-wrap: break-word;
  border-radius: 0.5rem;
  border: 1px solid getColor('primary-100');

  .arrow {
    transform: rotate(45deg);
    position: absolute;
    width: 12px;
    height: 12px;
    background: #fff;
    border: solid getColor('primary-100');
    z-index: -1;
    pointer-events: none;
  }
}
</style>
