<template>
  <ZNativeSelect
    v-model="selectedValue"
    :options="options"
    no-default
   
    @change="sortOrderChanged"
    @focus="trackSortMenuViewed"
    @blur="trackSortMenuClosed"
  />
</template>

<script setup lang="ts">
import { SEARCH_SORT_TYPES } from '~/constants/search'
import type { SegmentTypes } from '~/types/api/rental-segment-types'

const { t } = useI18n()
const { $search } = useNuxtApp()

const selectedValue = ref($search.parameters.sort.value)

const options = computed(() => {
  return Object.keys(SEARCH_SORT_TYPES).map((key) => ({
    label: `${key === $search.parameters.sort.value ? `${t('sortBy')} ` : ''}${t(key)}`,
    value: key,
  }))
})

const { trackSortMenuClosed, trackSortMenuViewed, trackSortOptionClicked } = useTracking()
function sortOrderChanged(event: Event) {
  const value = (event?.target as HTMLSelectElement).value

  trackSortOptionClicked({
    sortOption: (value?.toLowerCase() as SegmentTypes['SortOptionClicked']['sortOption']),
  })
  $search.updateSortOrder(value)
}
</script>

<style lang="scss" scoped>
select {
  @include caption;
  padding: 0.75rem 2rem 0.75rem 0.75rem;
  height: 2.75rem;

  @include media-min-size(medium) {
    width: 250px;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "sortBy": "Sort By:",
    "Recommended": "Recommended",
    "PriceASC": "Price: Low to High",
    "PriceDESC": "Price: High to Low",
    "DistanceASC": "Distance: Closest First"
  },
  "fr": {
    "sortBy": "Trier par :",
    "Recommended": "Recommandation",
    "PriceASC": "Prix croissant",
    "PriceDESC": "Prix décroissant",
    "DistanceASC": "Distance"
  }
}
</i18n>
