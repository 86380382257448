<template>
  <ZButton
    variant="highlight"
    :disabled="isUserAlsoOwner"
    :size="size"
    :block="!inlineButton"
   
    @click="goToRequestToBook"
  >
    <template v-if="!hasDateRange">
      {{ t('checkAvailability') }}
    </template>
    <template v-else-if="bookingEstimateData?.InstabookAvailable">
      <Fa
        :icon="['fas', 'bolt']"
        class="mr-2"
      />{{ t('bookNow') }}
    </template>
    <template v-else>
      {{ t('requestToBook') }}
    </template>
  </ZButton>
</template>

<script setup lang="ts">
import { ROUTE_BASE_NAME } from '~/constants'
import { messageAppToSignup } from '~/lib/mobileMessages/messageAppToSignup'

import type { ButtonSize } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  size?: ButtonSize
  inlineButton?: boolean
  dataTestId?: string
  pageSource?: string
  enquiryId?: number
}>(), {
  size: 'md',
  dataTestId: 'request-to-book',
})

const emit = defineEmits<{
  'open-breakdown': []
  'close-breakdown': []
}>()
const { t } = useI18n()
const route = useRoute()
const localePath = useLocalePath()

const { isWebView } = usePlatform()
const { isLoggedIn, user } = useAuthentication()
const { bookingEstimateData, bookingEstimateParams, hasDateRange } = useBookingEstimate()
const { rvAliasName, rvId, rvOwnerId } = useRvDetails()
const { routeBaseName } = useBaseName()

const isUserAlsoOwner = computed(() => rvOwnerId.value === user.value?.Id)

async function goToRequestToBook() {
  if (!bookingEstimateData.value) {
    emit('open-breakdown')
    return
  }

  emit('close-breakdown')

  // Redirect to request to book
  const augmentedQuery = {
    from: props.pageSource ?? routeBaseName.value,
    enquiryid: props.enquiryId,
    StartDate: bookingEstimateParams.value.dateStart,
    EndDate: bookingEstimateParams.value.dateEnd,
    Adults: bookingEstimateParams.value.adults,
    Children: bookingEstimateParams.value.children,
    RvUsage: bookingEstimateParams.value.rvUsage,
    placeId: bookingEstimateParams.value.destination?.placeId,
    ...route.query,
  }

  const requestToBookUrl = localePath({
    name: ROUTE_BASE_NAME.rv_rental_request_to_book,
    params: { alias: rvAliasName.value },
    query: augmentedQuery,
  })

  if (isWebView.value && !isLoggedIn.value) {
    messageAppToSignup({
      successUrl: `${window.location.origin}${requestToBookUrl}`,
      failureUrl: window.location.href,
      cookies: {
        [rvId.value]: JSON.stringify({
          DateStart: bookingEstimateParams.value.dateStart,
          DateEnd: bookingEstimateParams.value.dateEnd,
          Adults: bookingEstimateParams.value.adults,
          Children: bookingEstimateParams.value.children,
          Pets: bookingEstimateParams.value.pets,
          Delivery: bookingEstimateParams.value.rvUsage === 'Delivery',
        }),
      },
    })
    return
  }

  await navigateTo(requestToBookUrl)
}
</script>

<i18n lang="json">
{
  "en": {
    "checkAvailability": "Check availability",
    "bookNow": "Book now",
    "requestToBook": "Request to book"
  },
  "fr": {
    "checkAvailability": "Vérifier la disponibilité",
    "bookNow": "Réserver maintenant",
    "requestToBook": "Demande de réservation"
  }
}
</i18n>
