<template>
  <div>
    <h2>{{ t('title') }}</h2>
    <GridList
      ref="steps"
      :results-length="closestDestinations?.length"
      :rows="1"
      scrollable
    >
      <template #default="{ visibleResults }">
        <NuxtLinkLocale
          v-for="(destination, index) in visibleResults"
          :key="destination.pregen_id"
         
          :to="{
            name: ROUTE_BASE_NAME.rv_rentals_destination,
            params: {
              destinationPath: closestDestinations[index].slug.split('/'),
            },
          }"
          class="place"
        >
          <span class="img-wrapper">
            <ZImage
              :src="closestDestinations[index].Image"
              :title="`${closestDestinations[index].City}, ${closestDestinations[index].Region}`"
              :alt="`${closestDestinations[index].City}, ${closestDestinations[index].Region}`"
            />
          </span>
          <span class="text">
            {{ closestDestinations[index].City }}
            <small>{{ closestDestinations[index].Region }}</small>
          </span>
        </NuxtLinkLocale>
      </template>
    </GridList>
  </div>
</template>

<script setup lang="ts">
import { ROUTE_BASE_NAME } from '~/constants'

const { t } = useI18n()
const { geolocation } = useGeolocation()
const runtimeConfig = useRuntimeConfig()
const closestDestinations = computed(() => {
  const destinations = [
    {
      slug: 'united-states/florida/miami',
      City: 'Miami',
      Region: 'Florida',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/miami.webp`,
      Latitude: 25.77427,
      Longitude: -80.19366,
    },
    {
      slug: 'united-states/florida/orange-county',
      City: 'Orange County',
      Region: 'Florida',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/port-orange.webp`,
      Latitude: 28.4844995,
      Longitude: -81.2518833,
    },
    {
      slug: 'united-states/california/los-angeles',
      City: 'Los Angeles',
      Region: 'California',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/los-angeles.webp`,
      Latitude: 34.05223,
      Longitude: -118.24368,
    },
    {
      slug: 'united-states/texas/dallas',
      City: 'Dallas',
      Region: 'Texas',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/dallas.webp`,
      Latitude: 32.78306,
      Longitude: -96.80667,
    },
    {
      slug: 'united-states/california/san-diego',
      City: 'San Diego',
      Region: 'California',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/san-diego.webp`,
      Latitude: 32.71533,
      Longitude: -117.15726,
    },
    {
      slug: 'united-states/nevada/las-vegas',
      City: 'Las Vegas',
      Region: 'Nevada',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/las-vegas.webp`,
      Latitude: 36.17497,
      Longitude: -115.13722,
    },
    {
      slug: 'canada/british-columbia/vancouver',
      City: 'Vancouver',
      Region: 'British Columbia',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/vancouver.webp`,
      Latitude: 49.262428,
      Longitude: -123.1155399,
    },
    {
      slug: 'canada/ontario/toronto',
      City: 'Toronto',
      Region: 'Ontario',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/toronto.webp`,
      Latitude: 43.70011,
      Longitude: -79.4163,
    },
    {
      slug: 'canada/quebec/quebec-city',
      City: 'Quebec City',
      Region: 'Quebec',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/quebec-city.webp`,
      Latitude: 46.81228,
      Longitude: -71.21454,
    },
    {
      slug: 'canada/ontario/ottawa',
      City: 'Ottawa',
      Region: 'Ontario',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/ottawa.webp`,
      Latitude: 45.41117,
      Longitude: -75.69812,
    },
    {
      slug: 'canada/quebec/montreal',
      City: 'Montreal',
      Region: 'Quebec',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/montreal.webp`,
      Latitude: 45.50884,
      Longitude: -73.58781,
    },
    {
      slug: 'canada/alberta/calgary',
      City: 'Calgary',
      Region: 'Alberta',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/calgary.webp`,
      Latitude: 51.05011,
      Longitude: -114.08529,
    },
    {
      slug: 'canada/alberta/edmonton',
      City: 'Edmonton',
      Region: 'Alberta',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/edmonton.webp`,
      Latitude: 53.55014,
      Longitude: -113.46871,
    },
    {
      slug: 'canada/nova-scotia/halifax',
      City: 'Halifax',
      Region: 'Nova Scotia',
      Image: `${runtimeConfig.public.staticAssetsURL}/destinations/halifax.webp`,
      Latitude: 44.6491198,
      Longitude: -63.5751339,
    },
  ]
  if (geolocation.value?.latitude && geolocation.value?.longitude && geolocation.value?.countryCode) {
    destinations.sort((a, b) => {
      if (typeof geolocation.value?.latitude === 'number' && typeof geolocation.value?.longitude === 'number') {
        const distanceA = distanceBetweenTwoPoints({
          lat: geolocation.value?.latitude,
          lng: geolocation.value?.longitude,
        }, {
          lat: a.Latitude,
          lng: a.Longitude,
        })
        const distanceB = distanceBetweenTwoPoints({
          lat: geolocation.value?.latitude,
          lng: geolocation.value?.longitude,
        }, {
          lat: b.Latitude,
          lng: b.Longitude,
        })

        return distanceA - distanceB
      }

      return 0
    })

    return destinations.slice(0, 6)
  }

  const randomDestinations = []
  while (randomDestinations.length < 6) {
    const randomIndex = Math.floor(Math.random() * destinations.length)
    randomDestinations.push(destinations[randomIndex])
    destinations.splice(randomIndex, 1)
  }
  return randomDestinations
})
</script>

<style lang="scss" scoped>
a:hover {
  text-decoration: none;
}
.top-destinations-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
  grid-auto-rows: 0;
  grid-template-rows: 1fr;
  align-items: flex-start;
  overflow: hidden;

  @include media-min-size(medium) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include media-min-size(large) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.place {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  min-width: 12rem;
  min-height: 10rem;

  .img-wrapper {
    border-radius: 1rem;
    overflow: hidden;
    width: 100%;
    padding-bottom: 100%;
    position: relative;

    img {
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .text {
    @include subtitle-0;
    @include extra-bold;

    color: getColor('primary-500');
    width: 100%;
    text-align: start;
    font-style: normal;
    font-weight: 600;
    padding-top: 0.5rem;

    small {
      @include captionBase;
      color: getColor('primary-350');
      display: block;
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "title": "Top destinations other renters are looking at right now"
  },
  "fr": {
    "title": "Les meilleures destinations que d’autres locataires recherchent actuellement"
  }
}
</i18n>
