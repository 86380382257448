<template>
  <span
    :class="computedClasses"
  >
    <slot>{{ text }}</slot>
  </span>
</template>

<script setup lang="ts">
import type { BadgeSize, BadgeVariant } from '~/types/style-guide'

const props = withDefaults(defineProps<{
  text?: string
  variant?: BadgeVariant
  size?: BadgeSize
  uppercase?: boolean
}>(), {
  variant: 'highlight',
  size: 'md',
})

const computedClasses = computed(() => {
  return [
    'zbadge',
    `zbadge-${props.variant}`,
    `zbadge-${props.size}`,
    {
      'text-uppercase': props.uppercase,
    },
  ]
})
</script>
