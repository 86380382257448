<template>
  <section class="about">
    <h3 class="mb-3">
      {{ rvFullModelLabel }}
    </h3>
    <AppCollapsibleContent
      v-if="rvRVDescription"
      :content="rvRVDescription"
      :line-clamp="3"
      more-button-variant="primary"
      is-show-more-button
    />

    <ZGrid class="mt-4">
      <RvRentalDetailItem
        :label="t('rv.sleeps')"
        :value="rvGuests"
        class="mb-3"
      />

      <template v-if="rvIsMotorized">
        <RvRentalDetailItem
          v-if="rvSeatbeltTwoPoint && rvSeatbeltTwoPoint > 0"
          :label="t('pages.owner.editRV.details.seatbeltTwoPoint')"
          :value="rvSeatbeltTwoPoint"
          class="mb-3"
        />
        <RvRentalDetailItem
          v-if="rvSeatbeltThreePoint && rvSeatbeltThreePoint > 0"
          :label="t('pages.owner.editRV.details.seatbeltThreePoint')"
          :value="rvSeatbeltThreePoint"
          class="mb-3"
        />
      </template>
      <RvRentalDetailItem
        v-if="rvSlideouts"
        :label="t('rv.slideouts')"
        :value="rvSlideouts"
      />
      <RvRentalDetailItem
        v-if="rvIsMotorized && rvLength"
        :label="t('rv.length')"
        :value="rvLength"
        :unit="t('unit.ft')"
      />

      <template v-if="rvIsTowable">
        <RvRentalDetailItem
          v-if="rvWeight"
          :label="t('rv.weight')"
          :value="rvWeight"
          :unit="t('unit.lbs')"
          class="mb-3 mb-lg-0"
        />

        <RvRentalDetailItem
          v-if="rvHitchSize"
          :label="t('rv.hitchSize')"
          :value="rvHitchSize"
        />

        <RvRentalDetailItem
          v-if="rvHitchWeight"
          :label="t('rv.hitchWeight')"
          :value="rvHitchWeight"
          :unit="t('unit.lbs')"
        />

        <RvRentalDetailItem
          v-if="rvLength && rvLength > 0"
          :label="t('rv.length')"
          :value="rvLength"
          :unit="t('unit.ft')"
          class="mb-3"
        />
      </template>

      <RvRentalDetailItem
        v-if="rvIsMotorized && rvFuelType"
        :label="t('fuelType')"
        :value="rvFuelType"
        class="mb-3"
      />

      <ZGridItem
        cols="6"
        lg="4"
      >
        <div class="placeholder">
          {{ t('typeOfRv') }}
        </div>
        <strong>{{ t(rvLocalizedRVType) }}</strong>
      </ZGridItem>
    </ZGrid>
  </section>
</template>

<script setup lang="ts">
const { t } = useI18n()

const {
  rvFuelType,
  rvFullModelLabel,
  rvGuests,
  rvHitchSize,
  rvHitchWeight,
  rvIsMotorized,
  rvIsTowable,
  rvLength,
  rvLocalizedRVType,
  rvRVDescription,
  rvSeatbeltThreePoint,
  rvSeatbeltTwoPoint,
  rvSlideouts,
  rvWeight,
} = useRvDetails()
</script>

<style lang="scss" scoped>
section.about {
  .collapsible-content {
    color: getColor('primary-500');
  }

  strong {
    @include strong-2;

    color: getColor('primary-500');

    @include media-max-size(large) {
      font-size: 1rem;
    }
  }

  .placeholder {
    @include media-max-size(large) {
      font-size: 0.875rem;
    }
  }
}
</style>

<i18n src="~/locales/common/rv/accommodations.json" lang="json" />

<i18n src="~/locales/common/units.json" lang="json" />

<i18n lang="json">
{
  "en": {
    "fuelType": "Fuel type",
    "typeOfRv": "Type of RV"
  },
  "fr": {
    "fuelType": "Type de carburant",
    "typeOfRv": "Type de VR"
  }
}
</i18n>
